exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colors-js": () => import("./../../../src/pages/colors.js" /* webpackChunkName: "component---src-pages-colors-js" */),
  "component---src-pages-development-github-js": () => import("./../../../src/pages/development/github.js" /* webpackChunkName: "component---src-pages-development-github-js" */),
  "component---src-pages-development-index-js": () => import("./../../../src/pages/development/index.js" /* webpackChunkName: "component---src-pages-development-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logos-js": () => import("./../../../src/pages/logos.js" /* webpackChunkName: "component---src-pages-logos-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */)
}

